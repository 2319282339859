import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import { CardData } from '@/app/types/database/DeckData';
import { MultipleChoiceQuestion } from '@/app/types/database/MultipleChoiceQuestion';
import Loader from '@/components/common/loader/Loader';
import React, { useEffect, useState } from 'react';

interface IProps {
  card: CardData;
  showHelpText: boolean;
  tryMultipleChoice: boolean;
  resetId: number;
}

export default function Card(props: IProps) {
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(-1);
  const [multipleChoiceFailed, setMultipleChoiceFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [multipleChoiceQuestion, setMultipleChoiceQuestion] =
    useState<MultipleChoiceQuestion | null>(null);

  const { generateMultipleChoiceQuestionFromCardData } = useDeck();
  const { isProEligible, isPro } = useCustomer();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const selectMultipleChoiceAnswer = (index: number) => {
    trackEvent(analyticsInstance, 'preview_card_click_mcq_answer');

    if (multipleChoiceQuestion.answerIndex === index) {
      trackEvent(analyticsInstance, 'preview_card_mcq_answer_correct');
    } else {
      trackEvent(analyticsInstance, 'preview_card_mcq_answer_incorrect');
    }

    setSelectedChoiceIndex(index);
  };

  const loadMultipleChoiceQuestion = async () => {
    try {
      setIsLoading(true);
      const question = await generateMultipleChoiceQuestionFromCardData(
        props.card,
        isProEligible || isPro
      );
      setMultipleChoiceQuestion(question);

      if (question.choices.length <= 1) {
        trackEvent(analyticsInstance, 'preview_card_mcq_failed');
        setMultipleChoiceFailed(true);
      } else {
        trackEvent(analyticsInstance, 'preview_card_show_mcq');
        setMultipleChoiceFailed(false);
      }
    } catch (e) {
      trackEvent(analyticsInstance, 'preview_card_mcq_error', {
        error: e.toString()
      });
      setMultipleChoiceFailed(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (props.tryMultipleChoice) {
      loadMultipleChoiceQuestion();
    } else {
      trackEvent(analyticsInstance, 'preview_card_show_flashcard');
    }
  }, [props.tryMultipleChoice]);

  useEffect(() => {
    setShowAnswer(false);
    setSelectedChoiceIndex(-1);
  }, [props.resetId]);

  const onCardClick = () => {
    const newShowAnswer = !showAnswer;

    trackEvent(analyticsInstance, 'preview_card_click_flashcard');

    if (newShowAnswer) {
      trackEvent(analyticsInstance, 'preview_card_show_answer');
    } else {
      trackEvent(analyticsInstance, 'preview_card_hide_answer');
    }

    setShowAnswer(!showAnswer);
  };

  const getChoiceClasses = (choiceIndex: number) => {
    const commonClasses =
      'bordered w-full rounded border-2 p-1 pl-3 text-left border-gray-300';

    if (selectedChoiceIndex === -1) {
      return `${commonClasses} hover:border-secondary hover:cursor-pointer hover:bg-primary hover:text-base-content`;
    }

    if (multipleChoiceQuestion.answerIndex === choiceIndex) {
      return `${commonClasses} border-green bg-green text-base-content`;
    }

    if (
      selectedChoiceIndex === choiceIndex &&
      multipleChoiceQuestion.answerIndex !== choiceIndex
    ) {
      return `${commonClasses} border-l-4 border-t-2 border-b-2 border-r-2 border-l-red border-r-gray-300 border-b-gray-300 border-t-gray-300 bg-gray-300`;
    }

    return commonClasses;
  };

  const getChoiceText = (choice: string, choiceIndex: number) => {
    const prefixes = ['a', 'b', 'c', 'd', 'e', 'f'];

    if (choiceIndex < prefixes.length) {
      return `${prefixes[choiceIndex]}. ${choice}`;
    }

    return choice;
  };

  let answerBody = <></>;

  if (isLoading) {
    answerBody = (
      <div className="flex w-full flex-col content-center items-center justify-center p-4 align-middle font-bold">
        <Loader />
      </div>
    );
  } else if (
    props.tryMultipleChoice &&
    multipleChoiceQuestion &&
    multipleChoiceQuestion.isGeneratedOnly &&
    !multipleChoiceFailed
  ) {
    answerBody = (
      <div className="flex flex-col content-start justify-center gap-2 p-4">
        {multipleChoiceQuestion.choices.map((choice, index) => (
          <div
            key={index}
            onClick={() => selectMultipleChoiceAnswer(index)}
            className={getChoiceClasses(index)}
          >
            {getChoiceText(choice, index)}
          </div>
        ))}
      </div>
    );
  } else {
    answerBody = (
      <div className="flex flex-col content-center justify-center p-4 font-bold">
        {showAnswer
          ? props.card.answer
          : props.showHelpText
          ? 'Click anywhere on the flashcard to show answer'
          : ''}
      </div>
    );
  }

  return (
    <div>
      <div
        onClick={onCardClick}
        className="grid min-h-64 grid-cols-1 divide-y rounded-lg bg-white text-sm text-black shadow"
      >
        <div className="flex flex-col content-center justify-center p-4">
          {props.card.question}
        </div>
        {answerBody}
      </div>
    </div>
  );
}
