import firebaseApp from '@/app/database/firebase-app';
import { ParsedContent } from '@/app/types/Generator/ParsedContent';
import { CardData } from '@/app/types/database/DeckData';
import { MultipleChoiceQuestion } from '@/app/types/database/MultipleChoiceQuestion';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.timeout = 0;

const getFlaschards = async (parsedContent: ParsedContent[]) => {
  const auth = getAuth(firebaseApp);

  const response = await axios.post<CardData[]>(
    `/api/flashcardsPreview`,
    parsedContent,
    {
      timeout: 0
    }
  );

  return response.data;
};

export default {
  getFlaschards
};
