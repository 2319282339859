import React, { useEffect, useState } from 'react';
import Progress from '@/components/common/progress/Progress';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import UploadButton from '@/components/common/upload-button/UploadButton';
import { DeckStatus } from '@/app/constants/DeckStatus';
import Loader from '@/components/common/loader/Loader';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Card from './card/Card';
import AlternateOptions from '@/components/common/alternate-options/AlternateOptions';
import { ParsedContent } from '@/app/types/Generator/ParsedContent';
import PreviewAPI from '@/app/api/PreviewAPI/PreviewAPI';
import { CardData } from '@/app/types/database/DeckData';
import { getAuth, signInAnonymously } from 'firebase/auth';
import firebaseApp from '@/app/database/firebase-app';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';
import { getDocument, PDFDocumentProxy } from 'pdfjs-dist/legacy/build/pdf.mjs';
import 'pdfjs-dist/build/pdf.worker.mjs';

interface IProps {
  hideAlternateOptions?: boolean;
  isYoutube?: boolean;
  isWikipedia?: boolean;
}

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const AnonymousPreview = (props: IProps) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const {
    uploadProgress,
    uploadingDeck,
    selectedDeckData,
    selectedDeck,
    createDeck
  } = useDeck();

  const { trackEvent, analyticsInstance } = useAnalytics();

  const { showAuth } = useAuth();
  const [resetId, setResetId] = useState(0);
  const [isFastGenerating, setIsFastGenerating] = useState(false);
  const [fastGeneratedCards, setFastGeneratedCards] = useState<CardData[]>([]);

  useEffect(() => {
    if (selectedDeck.status === DeckStatus.Complete) {
      trackEvent(analyticsInstance, 'preview_success');
    } else if (selectedDeck.status === DeckStatus.Failed) {
      trackEvent(analyticsInstance, 'preview_failed');
    }
  }, [selectedDeck.status]);

  const getPdfDocument = async (file: any) => {
    return new Promise<PDFDocumentProxy>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async function () {
        try {
          var typedarray = new Uint8Array(this.result as ArrayBuffer);
          const response: PDFDocumentProxy = await getDocument(typedarray)
            .promise;
          resolve(response);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const fastGenerate = async (parsedContent: ParsedContent[]) => {
    try {
      setIsFastGenerating(true);

      const flashcards = await PreviewAPI.getFlaschards(parsedContent);

      setFastGeneratedCards(flashcards);

      setIsFastGenerating(false);

      if (flashcards.length > 0) {
        return true;
      }
    } catch {}

    setIsFastGenerating(false);
    return false;
  };

  const onAlternateOptionsChange = async (
    deckName: string,
    file: any,
    videoId?: string
  ) => {
    if (videoId) {
      trackEvent(analyticsInstance, 'preview_upload', {
        upload_source: 'youtube',
        file_type: 'txt'
      });
    } else {
      trackEvent(analyticsInstance, 'preview_upload', {
        upload_source: 'wikipedia',
        file_type: 'txt'
      });
    }

    await onChange(deckName, file, true);
  };

  const onChange = async (
    deckName: string,
    file: any,
    skipEvent: boolean = false
  ) => {
    let parsedContent: ParsedContent[] = [];
    let customPages = [];

    try {
      const doc = await getPdfDocument(file);

      for (let i = 1; i <= Math.min(doc.numPages, 10); i++) {
        const page = await doc.getPage(i);

        const textContent = await page.getTextContent();

        const text = textContent.items
          .filter((item: any) => typeof item.str === 'string')
          .map((item: any) => {
            if (item.hasEOL) {
              return item.str + ' ';
            }
            return item.str;
          })
          .join('');

        if (text) {
          parsedContent.push({
            text,
            name: 'Page ' + i,
            index: i
          });
        }

        customPages.push(i);
      }
    } catch (e) {
      customPages = undefined;
      parsedContent = undefined;
    }

    if (
      (parsedContent === undefined || parsedContent.length === 0) &&
      file?.name?.endsWith('.txt')
    ) {
      const text = await file.text();
      parsedContent = [{ text, name: 'Page 1', index: 1 }];
      customPages = [1];
    }

    setSelectedFileName(deckName);

    const fileType = file.name.split('.').slice(-1)[0].toLowerCase();

    if (!skipEvent) {
      trackEvent(analyticsInstance, 'preview_upload', {
        upload_source: 'file',
        fileType
      });
    }

    if (parsedContent && parsedContent.length > 0) {
      const auth = getAuth(firebaseApp);

      // If someone is using the preview feature, sign them in anonymously...
      if (!auth.currentUser) {
        await signInAnonymously(auth);
      }

      const success = await fastGenerate(parsedContent);
      // If fast generating doesn't make any cards, fallback to the traditional preview
      if (!success) {
        createDeck(
          deckName,
          file,
          undefined,
          undefined,
          parsedContent,
          [1, 2, 3]
        );
        trackEvent(analyticsInstance, 'preview_legacy_generate', {
          fileType,
          attemptedFastGenerate: true
        });
      } else {
        trackEvent(analyticsInstance, 'preview_fast_generate', {
          fileType
        });
      }
    } else {
      createDeck(
        deckName,
        file,
        undefined,
        undefined,
        parsedContent,
        [1, 2, 3]
      );

      trackEvent(analyticsInstance, 'preview_legacy_generate', {
        fileType,
        attemptedFastGenerate: false
      });
    }
  };

  const onSignupClick = () => {
    trackEvent(analyticsInstance, 'preview_click_sign_up');
    showAuth(true);
  };

  const previewCards =
    fastGeneratedCards.length > 0
      ? fastGeneratedCards
      : selectedDeckData?.cards.slice(0, 5) || [];

  const onPreviewCardChange = (_: number, newIndex: number) => {
    trackEvent(analyticsInstance, 'preview_card_change');

    if (newIndex === previewCards.length) {
      trackEvent(analyticsInstance, 'preview_view_sign_up');
    }

    setResetId(newIndex);
  };

  if (isFastGenerating) {
    return (
      <div className="flex flex-col items-center">
        <p className="mb-4 mt-4 text-2xl italic leading-normal">
          Generating Flashcards for{' '}
          <span className="font-bold">{selectedFileName}</span>...
        </p>
        <Loader />
        <p className="mb-4 text-sm italic leading-normal">
          This should only take a few seconds
        </p>
      </div>
    );
  } else if (
    previewCards.length > 0 ||
    selectedDeck.status === DeckStatus.Complete
  ) {
    return (
      <Slider
        id="long-value-select"
        instanceId="long-value-select"
        beforeChange={onPreviewCardChange}
        {...settings}
      >
        {previewCards.map((card, index) => (
          <Card
            card={card}
            showHelpText={index === 0}
            tryMultipleChoice={index > 0 && index % 2 === 0}
            key={index}
            resetId={resetId}
          />
        ))}
        <div>
          <div className="grid min-h-64 grid-cols-1 divide-y rounded-lg bg-white text-sm text-black shadow">
            <div className="flex flex-col content-center justify-center p-4">
              <p>
                Sign up for free to start generating flashcards! Study with
                Limbiks or download and import your flashcards into Anki,
                Quizlet, Tinycards, Cram, or your favorite flashcard tool!
              </p>
              <button
                id="navAction"
                onClick={onSignupClick}
                className="focus:shadow-outline mx-auto mt-4 transform rounded-full bg-primary py-4 px-8 font-bold text-base-content shadow transition duration-300 ease-in-out hover:scale-105 hover:underline focus:outline-none"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </Slider>
    );
  } else if (!selectedDeck.id) {
    return (
      <div className="inline-flex items-center">
        <div>
          {props.hideAlternateOptions && <div className="mt-10"></div>}

          <div className="mb-8 text-2xl font-light leading-normal">
            Preview your generated flashcards
          </div>
          {!props.isYoutube && !props.isWikipedia && (
            <UploadButton disabled={false} onChange={onChange} />
          )}
          {(props.isYoutube || props.isWikipedia) && (
            <div className="mt-2 flex w-full items-center justify-center">
              <AlternateOptions
                disabled={false}
                onChange={onAlternateOptionsChange}
                isPreview
                isYoutube={props.isYoutube}
                isWikipedia={props.isWikipedia}
              />
            </div>
          )}
          {!props.hideAlternateOptions && (
            <>
              <div className="mt-3 flex items-center justify-center">
                <div className="w-full flex-1 border-t border-gray-600"></div>
                <div className="p-2 text-base-content">or try</div>
                <div className="w-full flex-1 border-t border-gray-600"></div>
              </div>

              <div className="mt-2 flex w-full items-center justify-center">
                <AlternateOptions
                  disabled={false}
                  onChange={onAlternateOptionsChange}
                  isPreview
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else if (
    !isFastGenerating &&
    previewCards.length === 0 &&
    selectedDeck.status === DeckStatus.PendingUpload &&
    !!uploadingDeck
  ) {
    return (
      <div className="flex flex-col items-center">
        <p className="mb-4 mt-4 text-2xl italic leading-normal">
          Uploading <span className="font-bold">{selectedDeck.name}</span>
        </p>
        <Progress percent={uploadProgress} />
      </div>
    );
  } else if (
    !isFastGenerating &&
    previewCards.length === 0 &&
    selectedDeck.status === DeckStatus.Generating
  ) {
    return (
      <div className="flex flex-col items-center">
        <p className="mb-4 mt-4 text-2xl italic leading-normal">
          Generating Flashcards for{' '}
          <span className="font-bold">{selectedFileName}</span>...
        </p>
        <Loader />
        <p className="mb-4 text-sm italic leading-normal">
          This should takes less than a minute
        </p>
      </div>
    );
  } else {
    return (
      <Slider
        id="long-value-select"
        instanceId="long-value-select"
        beforeChange={onPreviewCardChange}
        {...settings}
      >
        <div>
          <div className="grid min-h-64 grid-cols-1 divide-y rounded-lg bg-white text-sm text-black shadow">
            <div className="flex flex-col content-center justify-center p-4">
              <p>
                Sign up for free to start generating flashcards! Study with
                Limbiks or download and import your flashcards into Anki,
                Quizlet, Tinycards, Cram, or your favorite flashcard tool!
              </p>
              <button
                id="navAction"
                onClick={onSignupClick}
                className="focus:shadow-outline mx-auto mt-4 transform rounded-full bg-primary py-4 px-8 font-bold text-base-content shadow transition duration-300 ease-in-out hover:scale-105 hover:underline focus:outline-none"
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
};

export default AnonymousPreview;
